// extracted by mini-css-extract-plugin
export var bleedWrapper = "NavigationBlock-module--bleedWrapper--37dc6";
export var footerNavLink = "NavigationBlock-module--footerNavLink--61a54";
export var gridParent = "NavigationBlock-module--gridParent--a2f4f";
export var navigation = "NavigationBlock-module--navigation--3406a";
export var navigationRightArea = "NavigationBlock-module--navigationRightArea--3e84f";
export var seal = "NavigationBlock-module--seal--09bd0";
export var sealImage = "NavigationBlock-module--sealImage--baaf7";
export var seals = "NavigationBlock-module--seals--b99a1";
export var socialMediaLink = "NavigationBlock-module--socialMediaLink--a5ce6";
export var socialMediaLinks = "NavigationBlock-module--socialMediaLinks--74b5b";