// extracted by mini-css-extract-plugin
export var bleedWrapper = "Navigation-module--bleedWrapper--709a2";
export var burgerButton = "Navigation-module--burgerButton--c2459";
export var burgerIcon = "Navigation-module--burgerIcon--5700a";
export var button = "Navigation-module--button--a9a80";
export var container = "Navigation-module--container--a5274";
export var currentPage = "Navigation-module--currentPage--ba480";
export var fullNavWrapper = "Navigation-module--fullNavWrapper--bb4a3";
export var logo = "Navigation-module--logo--a7281";
export var mobileNavWrapper = "Navigation-module--mobileNavWrapper--d40e5";
export var navLink = "Navigation-module--navLink--80356";
export var navList = "Navigation-module--navList--69d97";
export var navListItem = "Navigation-module--navListItem--62f9b";